export const isJavascriptString = (str, field_name) => {
    str = str || "";
    if (str.trim() == "") {
        return false;
    }
    /*    
    "<body onload=alert('test1')"
    <b onmouseover=alert('Wufff!')>click me!</b>
    <IMG SRC=j&#X41vascript:alert('test2')>
    <script>doSomethingEvil();</script>
    <body background="javascript:alert("XSS")">
    <img src="javascript:alert("XSS");">
    <img dynsrc="javascript:alert('XSS')">
    <img lowsrc="javascript:alert('XSS')">
    <input type="image" src="javascript:alert('XSS');">
    <link rel="stylesheet" href="javascript:alert('XSS');">
    <table background="javascript:alert('XSS')">
    <td background="javascript:alert('XSS')">
    <div style="background-image: url(javascript:alert('XSS'))">
    <div style="width: expression(alert('XSS'));">
    <object type="text/x-scriptlet" data="http://hacker.com/xss.html">
    */

    // It seems that the frontend is already doing proper protection against XSS attacks.  We should definitely do 
    // thorough pen testing, but the intention of this function is to hide those attacks (those tasks look ugly especially 
    // when they show up during demos) if we can reasonably detect them.

    let javascript_function_pattern1_str = "(setInterval|setTimeout|attachEventHandler)";
    let javascript_function_pattern1_regex = new RegExp(javascript_function_pattern1_str,'mi');
    let confirm_alert_prompt_str = "(confirm|alert|promp)\\s*\\(";
    let confirm_alert_prompt_regex = new RegExp(confirm_alert_prompt_str, 'mi');

    let javascript_object_pattern1_str = "(window|document)";
    let javascript_object_pattern1_regex = new RegExp(javascript_object_pattern1_str, 'mi');
    let javascript_other_object_pattern1_str = "(location|cookie)";
    let javascript_other_object_pattern1_regex = new RegExp(javascript_other_object_pattern1_str, 'mi');

    let eval_regex = new RegExp('\\beval\\s*\\(','mi');

    let javascript_event_pattern_str = "(click|dblclick|mouse|error|beforeunload|unload|cut|copy|paste|close|drag|drop|focus|blur|load|submit|gamepadconnected|gamepaddisconnected|hashchange|inputreport|success|change|keyup|keydown|keypress|addstream|DOMNodeInserted|DOMAttrModified|DOMAttributeNameChanged|DOMCharacterDataModified|DOMElementNameChanged|DOMNodeInsertedIntoDocument|DOMNodeRemoved|DOMNodeRemovedFromDocument|DOMSubtreeModified|pageshow|shippingaddresschange|shippingoptionchange|progress|loadend|send|icecandidate|touch|addtrack|removetrack|transitioncancel|transitionend|transitionrun|transitionstart|webglcontextlost|wheel)";
    let javascript_event_pattern2_str = "(on)" + javascript_event_pattern_str;
    let javascript_event_pattern3_str = "\\.\s*" + javascript_event_pattern_str + "\\b";
    let javascript_event_pattern2_regex = new RegExp(javascript_event_pattern2_str, 'im');
    let javascript_event_pattern3_regex = new RegExp(javascript_event_pattern3_str, 'im');

    /*
    Note that we use \\b in the above code to detect word boundary.  This is because we tried to put a Github Action 
    file into the description, and it has:

    if: steps.check_file.outputs.changed == 'true'

    This looks too much like JavaScript, and this function previously detect this as JavaScript.
    Adding the \\b above prevent this.
    */

    let javascript_attribute_pattern = "\\.\\s*(src|href|innerHTML|setAttribute|dynsrc|lowsrc)\\s*=";
    let javascript_attribute_pattern_regex = new RegExp(javascript_attribute_pattern, 'im');

    let javascript_colon_pattern_str = "javascript\s*:";
    let javascript_colon_pattern_regex = new RegExp(javascript_colon_pattern_str, 'mi');

    let html_elements_pattern_str = "(html|body|div|span|table|tr|td|img|svg|script|input|link)";
    let html_elements_pattern_regex = new RegExp(html_elements_pattern_str, 'mi');

    if (field_name == 'title') {
        if (str.search(/\&(nbsp|gt|lt)/) > -1) {
            // If these occur on the title, it just look ugly
            return true;
        }
    }

    try {
        str = unescape(str)
        str = decodeURI(str);
    } catch(e) {
        /*
        The decodeURI function called above was throwing "URI malformed" exception when str was:
        <ul><li><strong>Command</strong>: <em>ps aux --sort=-%mem | head -5</em></li><li><strong>Purpose</strong>: Lists the top 5 processes by memory usage.</li></ul>

        I cannot see why it would thrown the exception. Ignore.
        */
    }

    // Check the common patterns first
    if (javascript_attribute_pattern_regex.test(str)) {
        return true;
    } else if (javascript_event_pattern2_regex.test(str)) {
        return true;
    } else if (javascript_event_pattern3_regex.test(str)) {
        return true;
    } else if (javascript_function_pattern1_regex.test(str)) {
        return true;
    } else if ((str.toLowerCase().includes('join')) && (str.toLowerCase().includes('eval'))) {
        if ((field_name == 'title') || (field_name == 'description')) {
            return true; // Do not allow the title or description field to contains both eval and join
        } else {
            // more logic to be added for commands and scripts
        }
    } else if (eval_regex.test(str)) {
        if ((field_name == 'title') || (field_name == 'description')) {
            return true;
        } else {
            // more logic to be added for commands and scripts
        }
    } else if ((confirm_alert_prompt_regex.test(str)) && ((eval_regex.test(str)) || (str.toLowerCase().includes('script')))) {
        return true;
        // May need to add more logic here to handle commands and scripts later if needed
    } else if ((confirm_alert_prompt_regex.test(str)) && (html_elements_pattern_regex.test(str))) {
        return true;
        // May need to add more logic here to handle commands and scripts later if needed
    } else if ((javascript_other_object_pattern1_regex.test(str)) && (javascript_object_pattern1_regex.test(str))) {
        return true;
        // May need to add more logic here to handle commands and scripts later if needed
    } else if (javascript_colon_pattern_regex.test(str)) {
        return true;
    } else {
        // And then consider the field_name
        if (field_name == 'title') {
            if ((str.search(/[<>]/) > -1) && (str.split(/[<>]/).length >= 3)) {
                return true;
            }
        }    
    }

    return false;

}